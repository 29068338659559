.project-wrapper {
    position: relative;
  
    .caption {
      position: absolute;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6); // Semi-transparent black
      color: white; // Text color
      width: 100%; // Full width of the image
      text-align: center; // Center the text
      padding: 10px 0; // Padding inside the caption
      font-size: 20px; // Font size for text
      border-radius: 15px; // Match the image's rounded corners
      opacity: 0; // Hidden by default
      transition: opacity 0.3s ease; // Smooth transition
      overflow: hidden; // Ensure no overflow outside the border radius
    }
  
    &:hover .caption {
      opacity: 1; // Show caption on hover
    }
  }
  